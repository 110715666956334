<template>
  <div
    class="pa-card-wrapper m-0 w-full"
    :class="{
      'pa-card-wrapper_mobile': isMobile,
      pa_iframe: isLoadedInIframe
    }"
  >
    <div class="pa-card-widget">
      <template v-if="!isLoading && company && appointment">
        <div v-if="!hasWhiteLabel && !isLoadedInIframe" class="pa-card__main-heading">
          <a href="https://www.letsconnect.at/" class="pa-card__main-heading__logo-wrapper">
            <img src="@/assets/images/register-funnel/vega_logo.svg" alt="Logo" class="pa-card__main-heading__logo-img" />
          </a>
        </div>

        <button v-if="isLoadedInIframe" class="pathadvice__button-back" @click.prevent="sendBackEventToParent">
          <ArrowIcon :width="9" :height="9" class="mr-2" :color="'#575757'" />

          {{ $tp('appointmentBooking.back') }}
        </button>

        <div v-if="!isSent" class="pa-card__main-title">{{ $tp('appointmentBooking.chooseTheRightTimeForYou') }}</div>

        <div class="pa-card__widget">
          <div v-if="!isFormStep" class="pa-card-iframe">
            <div class="pa-card-iframe__inner" :class="{ 'pa-card-iframe__inner-slots-step': isSlotsStep }">
              <div v-if="isMobile ? isDatePickerStep : true" class="pa-card__calendar-wrapper">
                <Avatar v-if="uploadedPictureURL" class="pa-card__widget-avatar" size="72px" :photoURL="uploadedPictureURL" />

                <div class="pa-card__widget-title">{{ appointment.name || appointment.companyDisplayName }}</div>

                <DatePicker
                  :locale="$i18n.locale"
                  :is-expanded="true"
                  :is-required="true"
                  :min-page="minPage"
                  :max-page="maxPage"
                  :disabled-dates="disabledDates"
                  :available-dates="availableDates"
                  :value="selectedDate"
                  ref="calendar"
                  v-model="selectedDate"
                  :masks="{ title: 'MMMM', weekdays: 'WWW' }"
                  :attributes="attributes"
                  @dayclick="onDayClick"
                  @update:page="pageChange"
                  @update:from-page="pageChange"
                />
              </div>

              <div v-if="isMobile ? isSlotsStep : true" class="pa-card__slots-wrapper">
                <div class="pa-card__slots__title">{{ $tp('appointmentBooking.whatTimeWorksBest') }}</div>

                <div class="pa-card__slots__timezone-wrapper">
                  <select-timezone
                    @timezone-selected="onTimezoneSelected"
                    @selected-hour-format="onSelectedHourFormat"
                    :isDisabled="!availableDates || availableDates.length === 0"
                  ></select-timezone>
                </div>

                <div
                  v-if="filteredSelectedWeekDays && filteredSelectedWeekDays.times && filteredSelectedWeekDays.times.length"
                  class="pa-card__slots__list"
                  :style="`${isLoadedInIframe ? `max-height: ${maxHeightSlotsList}px` : ''}`"
                >
                  <div v-for="slot in filteredSelectedWeekDays.times" class="pa-card__slots__list__item" :key="slot.dateFormated" @click="selectSlot(slot)">
                    {{ displaySlotTime(slot) }}
                  </div>
                </div>

                <div v-else class="pa-card__slots__empty-text">{{ $tp('appointmentBooking.noFreeSlotAvailable') }}</div>
              </div>
            </div>

            <div v-if="isSlotsStep && isMobile" class="pa-card-widget__footer">
              <vs-button
                type="border"
                color="#275D73"
                @click.prevent="
                  isDatePickerStep = true
                  isSlotsStep = false
                "
              >
                <ArrowIcon :width="9" :height="9" class="mr-2" color="#275D73" />

                {{ $tp('appointmentBooking.back') }}
              </vs-button>
            </div>
          </div>
        </div>

        <div v-if="isFormStep && !isSent" class="pa-card-widget-data-wrapper">
          <div class="pa-card-widget-data">
            <div class="pa-card-widget-data__heading">
              <div class="pa-card-widget-data__heading__title">
                {{ $tp('appointmentBooking.yourInformation') }}
              </div>

              <div class="pa-card-widget-data__heading__date-wrapper">
                <div class="pa-card-widget-data__heading__date">{{ formattedDate }}</div>
                <div
                  class="pa-card-widget-data__heading__date-button"
                  @click.prevent="
                    isFormStep = false
                    isSlotsStep = true
                  "
                >
                  {{ $tp('appointmentBooking.edit') }}
                </div>
              </div>

              <div class="mt-1">{{ appointment.duration }} min</div>
              <div class="mt-1">{{ onlineMeetingToolOfSelectedAgent }}</div>
            </div>

            <div class="pa-card-widget__form">
              <div
                class="pa-card-widget__form__field"
                :class="{
                  'pa-card-widget__form__field_invalid': errors.first('name')
                }"
              >
                <div class="pa-card-widget__form__field__label">{{ $tp('appointmentBooking.name') | modifyInputPlaceholder(true) }}</div>
                <input
                  name="name"
                  key="name"
                  class="pa-card-widget__form__input"
                  v-model="name"
                  v-validate="{
                    required: true,
                    max: 80
                  }"
                />

                <p v-if="errors.first('name')" class="pa-card-widget__form__field__error">* {{ errors.first('name') }}</p>
              </div>

              <div
                class="pa-card-widget__form__field"
                :class="{
                  'pa-card-widget__form__field_invalid': errors.first('email')
                }"
              >
                <div class="pa-card-widget__form__field__label">{{ $tp('appointmentBooking.emailAddress') | modifyInputPlaceholder(true) }}</div>
                <input
                  name="email"
                  key="email"
                  class="pa-card-widget__form__input"
                  v-model="email"
                  v-validate="{
                    required: true,
                    email: true
                  }"
                />

                <p v-if="errors.first('email')" class="pa-card-widget__form__field__error">* {{ errors.first('email') }}</p>
              </div>

              <div
                class="pa-card-widget__form__field"
                :class="{
                  'pa-card-widget__form__field_invalid': errors.first('message')
                }"
              >
                <div class="pa-card-widget__form__field__label">{{ $tp('appointmentBooking.message') | modifyInputPlaceholder(true) }}</div>
                <textarea
                  name="message"
                  key="message"
                  class="pa-card-widget__form__input pa-card-widget__form__textarea"
                  v-model="message"
                  v-validate="{
                    required: true,
                    max: 500
                  }"
                />

                <p v-if="errors.first('message')" class="pa-card-widget__form__field__error">* {{ errors.first('message') }}</p>
              </div>

              <div
                v-if="appointment && appointment.isPrivacyInformationEnabled"
                class="pa-card-widget__form__field _privacy"
                :class="{
                  'pa-card-widget__form__field_invalid': errors.first('privacy-agreement')
                }"
              >
                <div
                  class="flex flex-row pathadvice__contact-form__privacy-policy-wrapper"
                  :class="{
                    'pathadvice__contact-form__privacy-policy': errors.first('privacy-agreement')
                  }"
                >
                  <div>
                    <vs-checkbox
                      name="privacy-agreement"
                      key="privacy-agreement"
                      class="pathadvice__contact-form__privacy-policy__checkbox"
                      v-validate="'required:true'"
                      v-model="hasAgreedToPrivacy"
                    >
                    </vs-checkbox>
                  </div>

                  <div class="flex flex-row pathadvice__contact-form__privacy-policy__text">
                    <span v-html="appointment.privacyInformationHtml"></span>
                  </div>
                </div>

                <p v-if="errors.first('privacy-agreement')" class="pathadvice__contact-form__main-container__form__field__error-checkbox">
                  * {{ errors.first('privacy-agreement') }}
                </p>
              </div>
            </div>

            <div class="pa-card-widget__footer pa-card-widget__footer-form">
              <vs-button
                type="border"
                color="#275D73"
                @click.prevent="
                  isFormStep = false
                  isSlotsStep = true
                "
              >
                <ArrowIcon :width="9" :height="9" class="mr-2" color="#275D73" />

                {{ $tp('appointmentBooking.back') }}
              </vs-button>

              <vs-button v-if="isFormStep" class="pa-card-widget__footer__button-confirm" @click.prevent="submit">{{
                $tp('appointmentBooking.confirm')
              }}</vs-button>
            </div>
          </div>
        </div>

        <div v-if="isSent" class="pa-card-widget-data-wrapper">
          <div class="pa-card__end-screen">
            <div class="pa-card__end-screen__heading">
              <div class="mb-0 pa-card__main-title pa-card__end-screen__title">{{ $tp('appointmentBooking.bookingSlotDefaultTitle') }}</div>
              <div class="pa-card__end-screen__description">
                {{ $tp('appointmentBooking.bookingSlotDefaultCopy') }} <br />
                {{ $tp('appointmentBooking.bookingSlotInvitationSent') }}
              </div>
            </div>

            <div class="pa-card__end-screen__info">
              <div class="pa-card__end-screen__info__company">{{ appointment.companyDisplayName || company.companyDisplayName || company.name }}</div>

              <div class="pa-card__end-screen__info__name">
                <QuoteIcon :width="16" :height="16" />
                <div class="ml-2">{{ appointment.name }}</div>
              </div>

              <div class="pa-card__end-screen__info__date-wrapper">
                <CalendarIcon :width="16" :height="16" color="#12598D" />

                <div class="ml-3 pa-card__end-screen__info__date">{{ formattedDate }}</div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>

    <!-- <div v-if="company && appointment" class="pa-card">
      <div class="pathadvice__contact-form__main-container__form-wrapper" style="height: 100%">
        <PoweredBy v-if="!hasWhiteLabel" class="pa-powered-by" />
      </div>
    </div> -->

    <div v-if="!isLoading && !(company || appointment)" class="pa-card__title">
      {{ $tp('appointmentBooking.companyNotFound') }}
    </div>
  </div>
</template>

<script>
import { DatePicker } from 'v-calendar'

import _ from 'underscore'

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
const relativeTime = require('dayjs/plugin/relativeTime')
const localizedFormat = require('dayjs/plugin/localizedFormat')
const isBetween = require('dayjs/plugin/isBetween')
const customParseFormat = require('dayjs/plugin/customParseFormat')

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)
dayjs.extend(isBetween)
dayjs.extend(customParseFormat)

import SelectTimezone from '@/components/SelectTimezone'
import ArrowIcon from '@/components/icons/ArrowIcon'
import QuoteIcon from '@/components/icons/QuoteIcon'
import CalendarIcon from '@/components/icons/CalendarIcon'
import Avatar from '@/components/Avatar'

const LETS_CONNECT_KEY = 'lets-connect'
const MICROSOFT_TEAMS_KEY = 'microsoft-teams'
const GOOGLE_MEET_KEY = 'google-meet'

const LETS_CONNECT_NAME = "Let's Connect Meeting"
const MICROSOFT_TEAMS_NAME = 'Microsoft Teams'
const GOOGLE_MEET_NAME = 'Google Meet'

export default {
  components: {
    SelectTimezone,
    ArrowIcon,
    QuoteIcon,
    CalendarIcon,
    Avatar,
    DatePicker
  },

  data: () => ({
    defaultAgentImage: require('@/assets/images/visitor/user.png'),

    maxHeightSlotsList: 350,

    isDatePickerStep: true,
    isSlotsStep: false,
    isFormStep: false,

    EU_TIMEZONES: require('@/assets/eutimezones.json'),
    isLoadedInIframe: false,
    SLOT_MARGIN_TOP: 10,
    SLOT_HEIGHT: 35,

    isMobile: false,
    isLoading: true,

    companyId: null,
    appointmentId: null,
    company: null,
    appointment: null,
    meetingId: null,

    name: '',
    email: '',
    date: '',
    message: '',
    agentId: '',

    duration: '',
    leadTime: '',
    bookingPeriodInWeeks: '',

    isOpenedCalendarWidget: false,
    isLoadingCalendarWidget: false,
    isLoadingSubmit: false,
    hasAgreedToPrivacy: false,
    isSent: false,
    selectedSlot: null,

    selectedWeekDays: [],
    currentDay: null,

    groups: [],
    timeslotsAgentsByWeekNumber: [],
    selectedTimezone: null,
    is24HourFormat: true,
    widgetBodyKey: Math.random().toString(36).substring(2, 15),

    days: [],

    selectedDate: null,

    onlineMeetingTools: {
      [LETS_CONNECT_KEY]: LETS_CONNECT_NAME,
      [MICROSOFT_TEAMS_KEY]: MICROSOFT_TEAMS_NAME,
      [GOOGLE_MEET_KEY]: GOOGLE_MEET_NAME
    }
  }),

  computed: {
    uploadedPictureURL() {
      if (!this.appointment) {
        return null
      }

      if (this.appointment.appointmentPhotoURL) {
        return this.appointment.appointmentPhotoURL
      }

      if (this.appointment.assignType === 'agent' && this.filteredTimeslotsAgentsByWeekNumber && this.filteredTimeslotsAgentsByWeekNumber.photoURL) {
        return this.filteredTimeslotsAgentsByWeekNumber.photoURL
      }

      return null
    },

    minPage() {
      if (!this.availableDates || !this.availableDates.length) {
        return {
          month: dayjs().month() + 1,
          year: dayjs().year()
        }
      }

      const firstDate = dayjs(this.availableDates[0])

      return {
        month: firstDate.month() + 1,
        year: firstDate.year()
      }
    },

    maxPage() {
      if (!this.availableDates || !this.availableDates.length) {
        return {
          month: dayjs().month() + 1,
          year: dayjs().year()
        }
      }

      const lastDate = dayjs(this.availableDates[this.availableDates.length - 1])

      return {
        month: lastDate.month() + 1,
        year: lastDate.year()
      }
    },

    onlineMeetingToolOfSelectedAgent() {
      let onlineMeetingTool = LETS_CONNECT_NAME

      if (this.agentId && this.timeslotsAgentsByWeekNumber) {
        this.timeslotsAgentsByWeekNumber.forEach((parentItem) => {
          const { timeslotsAgentsArray } = parentItem

          if (timeslotsAgentsArray && timeslotsAgentsArray.data) {
            timeslotsAgentsArray.data.forEach((childItem) => {
              if (
                childItem.userId === this.agentId &&
                childItem.syncedCalendarOnlineMeetingToolId &&
                this.onlineMeetingTools[childItem.syncedCalendarOnlineMeetingToolId]
              ) {
                onlineMeetingTool = this.onlineMeetingTools[childItem.syncedCalendarOnlineMeetingToolId]
              }
            })
          }
        })
      }

      return onlineMeetingTool
    },

    filteredTimeslotsAgentsByWeekNumber() {
      if (this.timeslotsAgentsByWeekNumber && this.timeslotsAgentsByWeekNumber.length && this.timeslotsAgentsByWeekNumber[0].timeslotsAgentsArray) {
        const { data } = this.timeslotsAgentsByWeekNumber[0].timeslotsAgentsArray

        return data[0]
      }

      return null
    },

    filteredSelectedWeekDays() {
      if (!this.selectedWeekDays || !this.selectedDate) {
        return []
      }

      const filteredSelectedWeekDays = [...this.selectedWeekDays].filter((item) => {
        if (!item || !item.times || !(item.times.length > 0)) {
          return false
        }

        if (!this.selectedDate) {
          this.selectedDate = new Date()
        }

        if (dayjs(item.day).isSame(dayjs(this.selectedDate), 'day')) {
          return true
        }
      })

      return filteredSelectedWeekDays[0]
    },

    availableDates() {
      if (!this.selectedWeekDays) {
        return []
      }

      const filteredSelectedWeekDays = this.selectedWeekDays.filter((item) => item.times && item.times.length > 0)
      const availableDates = filteredSelectedWeekDays.map((item) => item.m.$d)

      return availableDates
    },

    disabledDates() {
      if (!this.availableDates || this.availableDates.length === 0) {
        return [{ start: null, end: null }]
      }

      return []
    },

    dates() {
      return this.days.map((day) => day.date)
    },
    attributes() {
      return this.dates.map((date) => ({
        highlight: true,
        dates: date
      }))
    },

    selectedTimezoneString() {
      return this.selectedTimezone && this.selectedTimezone.name ? this.selectedTimezone.name : dayjs.tz.guess()
    },
    timezoneFormat() {
      return this.is24HourFormat ? 'HH:mm' : 'hh:mm A'
    },
    hasWhiteLabel() {
      if (!this.company) {
        return false
      }
      let claims = this.company.claims || []
      const custom_claims = this.company.claims_custom || []
      claims = [...new Set(claims.concat(custom_claims))]

      return (
        this.company &&
        this.company.planType !== 'trial' &&
        this.company.paymentStatus !== 'trial' &&
        claims.includes('white-label') &&
        this.company.expiryDate &&
        dayjs().isBefore(dayjs.unix(this.company.expiryDate.seconds))
      )
    },
    contactFormStyle() {
      return {
        color: '#575757',
        background: '#ffffff'
      }
    },

    countOfLargerSelectedWeek() {
      let countOfItems = 0

      this.selectedWeekDays.forEach((weekday) => {
        if (weekday.times.length > countOfItems) {
          countOfItems = weekday.times.length
        }
      })

      return countOfItems
    },

    heigthOfNoSlotBlock() {
      if (this.countOfLargerSelectedWeek) {
        const countHeightOfItems = this.countOfLargerSelectedWeek * this.SLOT_HEIGHT
        const countMarginOfItems = (this.countOfLargerSelectedWeek - 1) * this.SLOT_MARGIN_TOP

        return `${countHeightOfItems + countMarginOfItems}px`
      }

      return '100%'
    },

    formattedDate() {
      if (!this.selectedSlot) {
        return ''
      }
      const meetingDate = this.selectedSlot.timestamp
      let meetingDateString = meetingDate.locale(this.$i18n.locale).format(`dddd, MMMM D, YYYY ${this.timezoneFormat}`)
      if (this.selectedTimezone) {
        meetingDateString = this.selectedSlot.timestamp
          .utcOffset(this.selectedTimezone.dstOffset)
          .locale(this.$i18n.locale)
          .format(`dddd, MMMM D, YYYY ${this.timezoneFormat}`)
      }
      return meetingDateString
    }
  },

  async created() {
    await this.$vs.loading()

    this.isLoadedInIframe = this.$route.query.isIFrame && this.$route.query.isIFrame === 'true'
    this.isMobile = this.mobileCheck() || this.isLoadedInIframe
    this.companyId = this.$route.params.id
    this.appointmentId = this.$route.query.cid

    if (this.companyId) {
      const locale = navigator.language.slice(0, 2)
      this.updateCurrentLanguage(locale)
      await this.getSavedTranslationOfAccount(this.companyId, locale)
    }

    if (this.companyId && this.appointmentId) {
      await this.fetchCompany()
      await this.fetchAppointmentData()

      await this.openCalendarWidget()

      if (this.selectedWeekDays) {
        const filteredSelectedWeekDays = this.selectedWeekDays.filter((item) => item.times && item.times.length > 0)

        if (filteredSelectedWeekDays && filteredSelectedWeekDays[0] && !this.isMobile) {
          this.selectedDate = filteredSelectedWeekDays[0].m.$d
        }
      }
    }

    if (this.isLoadedInIframe) {
      const height = document.documentElement.clientHeight

      const modifiedHeight = height - 275

      this.maxHeightSlotsList = modifiedHeight < 250 ? 280 : modifiedHeight
    }

    await this.$vs.loading.close()

    this.isLoading = false
  },

  beforeMount() {
    this.is24HourFormat = this.EU_TIMEZONES.includes(dayjs.tz.guess())
  },

  mounted() {
    this.updateArrowsUI()
  },

  methods: {
    updateArrowsUI() {
      this.$nextTick(() => {
        setTimeout(() => {
          const calendar = this.$refs.calendar

          if (!calendar || !calendar.$el) {
            return
          }

          const { $el } = calendar

          const calendarArrows = $el.querySelector('.vc-arrows-container')
          const calendarTitle = $el.querySelector('.vc-title')

          if (calendarTitle) {
            const calendarTitleWidth = calendarTitle.offsetWidth

            if (calendarArrows && calendarTitleWidth) {
              const MINIMUM_CALENDAR_TITLE_WIDTH = 80
              const DEFAULT_CALENDAR_TITLE_OFFSET = 100
              const DEFAULT_ARROWS_WRAPPER_WIDTH = 180

              const width =
                calendarTitleWidth < MINIMUM_CALENDAR_TITLE_WIDTH ? DEFAULT_ARROWS_WRAPPER_WIDTH : calendarTitleWidth + DEFAULT_CALENDAR_TITLE_OFFSET

              calendarArrows.style.width = `${width}px`
            }
          }
        }, 200)
      })
    },

    pageChange() {
      this.updateArrowsUI()
    },

    onDayClick(day) {
      this.isDatePickerStep = false
      this.isSlotsStep = true

      const idx = this.days.findIndex((d) => d.id === day.id)

      if (idx >= 0) {
        return null
      } else {
        this.days = [
          {
            id: day.id,
            date: day.date
          }
        ]
      }
    },
    displaySlotTime(slot) {
      if (this.selectedTimezone) {
        return slot.timestamp.utcOffset(this.selectedTimezone.dstOffset).format(this.timezoneFormat)
      }
      return slot.timestamp.format(this.timezoneFormat)
    },
    onSelectedHourFormat(val) {
      this.is24HourFormat = val
      this.widgetBodyKey = Math.random().toString(36).substring(2, 15)
    },
    onTimezoneSelected(timezone) {
      this.selectedTimezone = timezone
      this.widgetBodyKey = Math.random().toString(36).substring(2, 15)
    },
    sendBackEventToParent() {
      parent.postMessage({ name: 'book-meeting', action: 'go-back' }, '*')
    },
    mobileCheck() {
      if (this.$route && this.$route.query && this.$route.query.isMobile && this.$route.query.isMobile === 'true') {
        return true
      }

      let check = false

      ;(function (a) {
        if (
          // eslint-disable-next-line
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          // eslint-disable-next-line
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        ) {
          check = true
        }
      })(navigator.userAgent || navigator.vendor || window.opera)

      return check
    },

    async fetchCompany() {
      const companyRef = await this.$db.collection('company').doc(this.companyId).get()

      this.company = companyRef.data()
    },

    async fetchAppointmentData() {
      const appointmentRef = await this.$db.collection('company').doc(this.companyId).collection('appointments').doc(this.appointmentId).get()

      const appointmentData = appointmentRef.data()

      this.appointment = appointmentData
      this.duration = appointmentData.duration
      this.leadTime = appointmentData.leadTime
      this.bookingPeriodInWeeks = appointmentData.bookingPeriodInWeeks
    },

    async submit() {
      const result = await this.$validator.validateAll()

      if (!result || !this.company || !this.appointment) {
        return
      }

      parent.parent.postMessage({ name: 'oribi', action: 'clicked-book-meeting' }, '*')
      parent.parent.postMessage({ name: '__lc-events__', action: 'clicked-book-meeting' }, '*')

      await this.$vs.loading()

      this.isLoadingSubmit = true

      const data = {
        companyId: this.companyId,
        name: this.name,
        email: this.email,
        message: this.message ? this.message.replace(/\n/g, '<br>') : '',
        emailSubject: this.$i18n.t('vue.joinMeeting'),
        locale: this.$i18n.locale,
        created: new Date().getTime(),
        contacted: false,
        status: 'confirmed'
      }

      if (!this.appointmentId) {
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.error'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })

        await this.$vs.loading.close()

        this.isLoadingSubmit = false

        return
      }

      data.agentId = this.agentId

      // This fix (solution) creates a valid date regardless of Daylight Saving Time and Standard Time.
      data.date = this.selectedSlot.timestamp.tz(this.selectedTimezoneString).utc().unix() * 1000
      // data.date = dayjs.tz(this.selectedSlot.dateFormated, 'YYYY-MM-DD HH:mm', this.selectedTimezoneString).valueOf()
      data.selectedAppointment = this.appointmentId

      try {
        await this.bookMeeting(data)
        parent.parent.postMessage({ name: 'oribi', action: 'scheduled-appointment-successfully' }, '*')
        parent.parent.postMessage({ name: '__lc-events__', action: 'scheduled-appointment-successfully' }, '*')
        this.isSent = true
      } catch (error) {
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.error'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }

      await this.$vs.loading.close()

      this.isLoadingSubmit = false
    },

    async bookMeeting(meetingData) {
      try {
        const scheduleAppointment = this.$functions.httpsCallable('scheduleAppointment')
        if (this.selectedSlot && this.selectedSlot.visitorTimezone) {
          meetingData.visitorTimezone = this.selectedTimezone && this.selectedTimezone.name ? this.selectedTimezone.name : this.selectedSlot.visitorTimezone
        }
        if (this.selectedSlot && this.selectedSlot.agentTimezone) {
          meetingData.agentTimezone = this.selectedSlot.agentTimezone
        }
        const language = navigator.language.slice(0, 2) || 'en'
        meetingData.clientBrowserLanguage = language

        const { data: responseScheduleAppointment } = await scheduleAppointment(meetingData)

        const meetingInfo = {
          meetingId: responseScheduleAppointment.id,
          env: process.env.NODE_ENV
        }

        this.meetingId = responseScheduleAppointment && responseScheduleAppointment.id ? responseScheduleAppointment.id : null

        const encryptedMeetingInfo = window.btoa(unescape(encodeURIComponent(JSON.stringify(meetingInfo))))

        await this.$db.collection('meetings').doc(responseScheduleAppointment.id).set({ encryptedMeetingInfo }, { merge: true })

        // const sendAppointmentEmail = this.$functions.httpsCallable('sendAppointmentEmail')

        // await sendAppointmentEmail({
        //   ...responseScheduleAppointment,
        //   encryptedMeetingInfo,
        //   agentTimezone: this.selectedSlot && this.selectedSlot.agentTimezone ? this.selectedSlot.agentTimezone : null,
        //   visitorTimezone: this.selectedSlot && this.selectedSlot.visitorTimezone ? this.selectedSlot.visitorTimezone : null
        // })
      } catch (error) {
        /* eslint-disable no-console */
        console.log(error)
      }
    },

    async openCalendarWidget() {
      this.isOpenedCalendarWidget = true

      if (!this.selectedWeekDays.length) {
        this.isLoadingCalendarWidget = true

        this.currentDay = dayjs()

        this.getWeekDaysOfWeek(dayjs().startOf('day'))
        await this.getCalenderItems()
        this.addWeekDayAndTimes()

        this.isLoadingCalendarWidget = false
      }
    },

    async selectSlot(slot) {
      if (dayjs().diff(slot.timestamp, 'minute') > 0) {
        return
      }

      this.selectedSlot = slot

      const meetingDate = slot.timestamp
      let meetingDateString = meetingDate.format('LLLL')
      if (this.selectedTimezone) {
        meetingDateString = slot.timestamp.utcOffset(this.selectedTimezone.dstOffset).format('LLLL')
      }

      const agentId = slot.users[Math.floor(Math.random() * slot.users.length)]

      this.agentId = agentId
      this.date = meetingDateString
      this.isFormStep = true
    },

    async getCalenderItems() {
      if (!this.companyId || !this.appointmentId) {
        return
      }

      const data = {
        dialogId: '',
        companyId: this.companyId,
        selectedAppointment: this.appointmentId,
        timezone: dayjs.tz.guess(),
        start: dayjs(this.selectedWeekDays[0].m).format(),
        end: dayjs(this.selectedWeekDays[this.selectedWeekDays.length - 1].m)
          .endOf('day')
          .format()
      }

      let response = null

      try {
        const calendarGetAvailableAgentsTimeslots = this.$functions.httpsCallable('calendarGetAvailableAgentsTimeslots')

        response = await calendarGetAvailableAgentsTimeslots(data)
      } catch {
        return
      }

      if (!response) {
        return
      }

      const modifiedAbilityData = this.modifyAbilityData(response.data)

      const timeslotsAgentsArray = {
        data: modifiedAbilityData
      }

      /* saving this data for caching & faster retrieval */
      const ts = this.timeslotsAgentsByWeekNumber.filter((x) => dayjs(x.leadDayInArray).format() === dayjs(this.currentDay).format())

      if (ts.length > 0) {
        this.timeslotsAgentsByWeekNumber.map(() => {
          return {
            timeslotsAgentsArray,
            leadDayInArray: this.currentDay
          }
        })
      } else {
        this.timeslotsAgentsByWeekNumber.push({
          timeslotsAgentsArray,
          leadDayInArray: this.currentDay
        })
      }

      await this.calculateTimeSlotsAgents(timeslotsAgentsArray)
    },

    async calculateTimeSlotsAgents(timeslotsAgentsArray) {
      let timeslots = []
      // round starting minutes up to nearest 15 (12 --> 15, 17 --> 30)
      // note that 59 will round up to 60, and moment.js handles that correctly

      /* default notice period if not saved is 2hrs / 120 minutes */
      const noticePeriodInMinutes = Number(this.leadTime)
      const duration = Number(this.duration)

      for (let i = 0; i < timeslotsAgentsArray.data.length; i++) {
        const agent = timeslotsAgentsArray.data[i]
        const selectedTimezone = agent && agent.timezone && agent.timezone.name ? agent.timezone.name : dayjs.tz.guess()

        for (let n = 0; n < agent.windows.length; n++) {
          const slot = agent.windows[n]
          const startDateWithLeadTime = dayjs(slot.start)

          let start = startDateWithLeadTime
          const end = dayjs(slot.end).add(-duration, 'minute')
          const minuteRounded = (Math.round(start.get('minute') / duration) * duration) % 60

          start = start.minute(minuteRounded)

          let current = start.clone()

          if (start.valueOf() < startDateWithLeadTime.valueOf()) {
            current = current.add(duration, 'minute')
          }

          while (current.valueOf() <= end.valueOf()) {
            const match = _.find(timeslots, (timeslot) => {
              return timeslot.dateFormated === current.format('YYYY-MM-DD HH:mm')
            })

            /* Get day of week of current day. Week should start from Monday. i.e Monday should have zero and Sunday = 6*/
            const dayOfWeek = current.day() - 1 < 0 ? 6 : current.day() - 1

            if (match && agent.availableTimeSlots.length > 0 && agent.availableTimeSlots[dayOfWeek].enabled) {
              /* Add to timeslots array only of agent has availability */
              // const format24hr = 'HH:mm'
              /* Loop through the agents available slots for the week day */
              for (let t = 0; t < agent.availableTimeSlots[dayOfWeek].times.length; t++) {
                const beforeHHmm = agent.availableTimeSlots[dayOfWeek].times[t].start.split(':')
                const afterHHmm = agent.availableTimeSlots[dayOfWeek].times[t].end.split(':')
                const time = current.clone()

                let beforeTime = dayjs(time.format('YYYY-MM-DD')).set('hour', beforeHHmm[0]).set('minute', beforeHHmm[1]),
                  afterTime = dayjs(time.format('YYYY-MM-DD')).set('hour', afterHHmm[0]).set('minute', afterHHmm[1])
                beforeTime = dayjs.tz(beforeTime.format('YYYY-MM-DD HH:mm'), selectedTimezone)
                afterTime = dayjs.tz(afterTime.format('YYYY-MM-DD HH:mm'), selectedTimezone)
                /* add to timeslots only if current time is in between the available time slots */

                if (
                  (time.isBetween(beforeTime, afterTime) || time.isSame(beforeTime)) &&
                  (dayjs(startDateWithLeadTime).isBefore(current) || dayjs(startDateWithLeadTime).isSame(current))
                ) {
                  // const timeslot = {
                  //   users: [agent.userId],
                  //   dateFormated: time.format('YYYY-MM-DD HH:mm'),
                  //   timestamp: time.clone(),
                  //   agentTimezone: selectedTimezone,
                  //   visitorTimezone: dayjs.tz.guess()
                  // }
                  // timeslots.push(timeslot)
                  match.users.push(agent.userId)
                  break
                }
              }
            } else {
              /* Check if todays events has atleast notice period time avaiable before it starts */
              /* Ex: if the notice period in minutes is 180, then make sure that if there are any events today, it has atleast 180 minutes left to start  */
              if (dayjs().format('YYYY-MM-DD') === current.format('YYYY-MM-DD')) {
                const minimumTime = dayjs().add(noticePeriodInMinutes, 'minute')
                const differenceTime = current.diff(minimumTime, 'minute')

                if (differenceTime <= 0) {
                  current = current.add(duration, 'minute')

                  continue
                }
              }

              /* Get day of week of current day. Week should start from Monday. i.e Monday should have zero and Sunday = 6*/
              const dayOfWeek = current.day() - 1 < 0 ? 6 : current.day() - 1

              /* Add to timeslots array only of agent has availability */
              if (agent.availableTimeSlots !== undefined && agent.availableTimeSlots.length > 0 && agent.availableTimeSlots[dayOfWeek].enabled) {
                //const format24hr = 'HH:mm'

                /* Loop through the agents available slots for the week day */
                for (let t = 0; t < agent.availableTimeSlots[dayOfWeek].times.length; t++) {
                  const beforeHHmm = agent.availableTimeSlots[dayOfWeek].times[t].start.split(':')
                  const afterHHmm = agent.availableTimeSlots[dayOfWeek].times[t].end.split(':')

                  const time = current.clone()
                  let beforeTime = dayjs(time.format('YYYY-MM-DD')).set('hour', beforeHHmm[0]).set('minute', beforeHHmm[1]),
                    afterTime = dayjs(time.format('YYYY-MM-DD')).set('hour', afterHHmm[0]).set('minute', afterHHmm[1])

                  beforeTime = dayjs.tz(beforeTime.format('YYYY-MM-DD HH:mm'), selectedTimezone)
                  afterTime = dayjs.tz(afterTime.format('YYYY-MM-DD HH:mm'), selectedTimezone)

                  /* add to timeslots only if current time is in between the available time slots */
                  if (
                    (time.isBetween(beforeTime, afterTime) || time.isSame(beforeTime)) &&
                    (dayjs(startDateWithLeadTime).isBefore(current) || dayjs(startDateWithLeadTime).isSame(current))
                  ) {
                    const timeslot = {
                      users: [agent.userId],
                      dateFormated: time.format('YYYY-MM-DD HH:mm'),
                      timestamp: time.clone(),
                      agentTimezone: selectedTimezone,
                      visitorTimezone: this.selectedTimezone && this.selectedTimezone.name ? this.selectedTimezone.name : dayjs.tz.guess()
                    }

                    timeslots.push(timeslot)

                    break
                  }
                }
              }
            }

            current = current.add(duration, 'minute')
          }
        }
      }

      const occurrenceDay = (timeslot) => {
        return dayjs(timeslot.timestamp).startOf('day').format()
      }

      const groupToDay = (group, day) => {
        return {
          day: dayjs(day).format('YYYY-MM-DD'),
          times: group,
          timestamp: day,
          active: false
        }
      }

      timeslots = timeslots.sort((a, b) => a.timestamp.unix() - b.timestamp.unix())

      this.groups = _.chain(timeslots).groupBy(occurrenceDay).map(groupToDay).sortBy('timestamp').value()

      this.$forceUpdate()
    },

    getWeekDaysOfWeek(day) {
      this.currentDay = day
      this.selectedWeekDays = []

      const countOfWeekDays = 6
      const dayOfCurrentWeek = dayjs().day()
      const bookingPeriodInWeeks = this.bookingPeriodInWeeks || 2
      const remainingDaysOfCurrentWeek = countOfWeekDays - dayOfCurrentWeek + 1
      const bookingPeriodInWeeksDays = (bookingPeriodInWeeks - 1) * 7
      const countOfItems = remainingDaysOfCurrentWeek + bookingPeriodInWeeksDays

      for (let i = 0; i <= countOfItems; i++) {
        this.selectedWeekDays.push({
          m: dayjs(this.currentDay).add(i, 'days').locale(this.$i18n.locale).startOf('day'),
          times: []
        })
      }
    },

    async nextWeek() {
      this.isLoadingCalendarWidget = true

      this.getWeekDaysOfWeek(
        dayjs(this.selectedWeekDays[this.selectedWeekDays.length - 1].m)
          .add(1, 'days')
          .startOf('day')
      )

      if (this.timeslotsAgentsByWeekNumber.filter((x) => dayjs(x.leadDayInArray).format() === dayjs(this.currentDay).format()).length > 0) {
        const tsa = this.timeslotsAgentsByWeekNumber.filter((x) => dayjs(x.leadDayInArray).format() === dayjs(this.currentDay).format())[0]

        await this.calculateTimeSlotsAgents(tsa.timeslotsAgentsArray)
        this.addWeekDayAndTimes()
      } else {
        await this.getCalenderItems()
        this.addWeekDayAndTimes()
      }

      this.isLoadingCalendarWidget = false
    },

    addWeekDayAndTimes() {
      this.selectedWeekDays.filter((weekday) => {
        const day = weekday.m.format('YYYY-MM-DD')
        weekday.day = day
        /* check if day exists in groups */
        const group = this.groups.filter((group) => group.day === day)

        if (group && group.length > 0) {
          weekday.times = group[0].times
        }
      })
    },

    modifyAbilityData(data) {
      if (!data) return []

      const modifiedData = data
        .map((item) => {
          if (!item || !item.windows || !Array.isArray(item.windows)) {
            return null
          }

          const modifiedWindows = item.windows.map((dateValues) => {
            const copyDateValues = { ...dateValues }

            Object.keys(copyDateValues).forEach((key) => {
              copyDateValues[key] = this.modifyDate(copyDateValues[key])
            })

            return copyDateValues
          })

          const copyItem = { ...item }

          copyItem.windows = modifiedWindows

          return copyItem
        })
        .filter((item) => !!item)

      return modifiedData
    },

    modifyDate(date) {
      if (!date) return null

      const utcDate = dayjs(date).format()
      const utcDateString = new Date(utcDate).toString()
      const localDate = dayjs.utc(new Date(utcDateString)).local().format()

      return localDate
    }
  },

  filters: {
    modifyInputPlaceholder(string, isRequired) {
      return isRequired ? `${string} *` : string
    }
  },

  watch: {
    isSent() {
      if (this.isSent) {
        const custom_event = new CustomEvent('custom_gtm_events')
        custom_event.info = {
          name: 'meeting_booked',
          gtm: {
            uniqueEventId:
              Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
            location: window.location.href,
            event_type: 'online-meeting',
            name: this.company && this.company.name ? this.company.name : '',
            appointment_name: this.appointment && this.appointment.name ? this.appointment.name : '',
            appointment_date: this.formattedDate,
            id: this.meetingId
          }
        }
        window.dispatchEvent(custom_event)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.pathadvice__button-back {
  display: flex;
  align-items: center;
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 13px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  background: none;
  border: none;
  cursor: pointer;
}

.pa-card__end-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: calc(100% - 26px);

  &__heading {
    color: rgb(87, 87, 87);
    text-align: center;

    @media (max-width: 767px) {
      text-align: left;
    }
  }

  &__title {
    font-size: 48px;
    font-weight: 700;
    line-height: 61px;

    @media (max-width: 767px) {
      font-size: 36px;
      line-height: 46px;
    }
  }

  &__description {
    margin-top: 20px;
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;

    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 1.5;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px auto 0;
    padding: 35px 20px;
    width: 100%;
    max-width: 512px;
    background: rgb(242, 242, 242);
    border-radius: 6px;

    &__company {
      font-weight: 700;
    }

    &__name {
      display: flex;
      margin-top: 25px;
      font-weight: 500;
    }

    &__date {
      color: rgb(18, 89, 141);
      font-weight: 500;

      &-wrapper {
        display: flex;
        margin-top: 25px;
      }
    }
  }
}

.pa_iframe {
  overflow: hidden;
}

.pa-powered-by {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background: rgb(255, 255, 255);
  box-shadow: none;
  border-radius: 8px;
}

.pa-card {
  position: relative;
  width: 100%;
  max-width: 1200px;
  height: 100%;

  @media (min-width: 768px) {
    margin: 20px 40px;
    height: 538px;
    border: 0.25px solid rgb(87, 87, 87);
    border-radius: 6px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  &-wrapper {
    background: rgb(255, 255, 255);
    padding-bottom: 40px;
  }

  &__heading {
    text-align: center;
  }

  &__title {
    color: rgb(87, 87, 87);
    font-size: 24px;
    font-weight: bold;
  }

  &__label {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    margin-right: 28px;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;

    &::before {
      content: '';
      position: absolute;
      top: -1px;
      right: -14px;
      width: 1.5px;
      height: 18px;
      height: 18px;
      border-radius: 6px;
      background: rgb(87, 87, 87);
    }

    &:last-of-type {
      margin-right: 0;

      &::before {
        display: none;
      }
    }

    &__icon {
      margin-bottom: 2px;
      width: 16px;
      height: 16px;
    }
  }

  &__description {
    margin-top: 12px;
    color: rgb(87, 87, 87);
    font-size: 14px;
    font-weight: bold;
  }
}
</style>

<style lang="scss">
body {
  background: rgb(255, 255, 255);
}

.pa_iframe {
  box-shadow: none;

  .pa-card__widget {
    padding: 0;
  }

  .pa-card-iframe {
    box-shadow: none;
  }

  .pa-card-widget-data {
    padding: 0;
    box-shadow: none;
  }

  &.pa-card-wrapper {
    padding-bottom: 0;
  }

  .pa-card {
    &__main-title {
      margin: 50px auto 20px;
    }

    &__calendar-wrapper {
      padding: 0;
      background: #fff;

      .vc-container {
        background: #fff;
      }

      .vc-day-content,
      .vc-highlight {
        width: 40px;
        height: 40px;
      }
    }

    &__slots-wrapper {
      padding: 0 16px 16px !important;
    }

    &-widget-data {
      &-wrapper {
        padding: 0;
      }

      &__heading {
        padding: 0 16px;
      }
    }

    &-widget {
      &__form {
        margin-bottom: 85px;
        padding: 0 16px;
      }

      &__footer {
        position: fixed;
        left: 0;
        bottom: 0;
        padding: 16px 16px 18px !important;
        width: 100%;
      }
    }

    &__end-screen {
      padding: 0 16px;
    }
  }
}

.pa-card-wrapper_mobile {
  .pa-card {
    &__calendar-wrapper {
      width: 100%;
    }

    &__main-title {
      font-size: 24px;
      line-height: 27px;
    }

    &__widget {
      &-title {
        font-size: 18px;
      }
    }

    &__slots {
      &-wrapper {
        padding: 32px;
        width: 100%;
      }

      &__list {
        max-height: 350px;
        height: 350px;
      }
    }

    &-widget {
      &__footer {
        padding: 16px 32px 18px;
        background: rgb(245, 248, 250);
        border-top: 1px solid rgb(203, 214, 226);

        &-form {
          margin-top: 30px;
        }
      }
    }
  }
}

.pa-card__main {
  &-heading {
    margin-left: auto;
    margin-right: auto;
    padding: 20px 15px;
    max-width: 1100px;

    &__logo-img {
      max-width: 150px;
    }
  }

  &-title {
    margin: 40px auto;
    padding: 0 15px;
    max-width: 1100px;
    text-align: center;
    color: rgb(18, 30, 48);
    font-size: 45px;
    font-weight: 600;
    line-height: 45px;
  }
}

.pa-card__widget {
  padding: 0 15px;

  &-title {
    margin-top: 16px;
    padding: 0 20px;
    font-size: 24px;
    color: rgb(51, 71, 91);
    text-align: center;
  }

  &-avatar {
    margin: 0 auto;
    width: 78px;
    height: 78px;
    background: rgb(255, 255, 255);
    border: 3px solid rgb(255, 255, 255);
    border-radius: 50%;
    cursor: auto;
  }
}

.pa-card-widget {
  &-data {
    margin: 0 auto;
    padding-top: 32px;
    max-width: 850px;
    font-family: 'Lato';
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(45, 62, 80, 0.12) 0px 1px 5px 0px;
    border-radius: 3px;

    * {
      font-family: 'Lato';
    }

    &-wrapper {
      padding: 0 15px;
    }

    &__heading {
      padding: 0 32px;
      color: rgb(51, 71, 91);

      &__title {
        font-size: 20px;
        font-weight: 600;
      }

      &__date {
        font-size: 16px;
        font-weight: 600;

        &-wrapper {
          display: flex;
          align-items: center;
          margin-top: 14px;
        }

        &-button {
          margin-left: 4px;
          color: #275d73;
          font-size: 14px;
          font-weight: 600;

          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }

    &_form-sent {
      padding-bottom: 32px;
      max-width: 400px;
    }

    &__end-screen {
      padding: 0 16px;
      text-align: center;

      &__description {
        color: rgb(51, 71, 91);
        font-size: 14px;
      }

      &__date {
        color: rgb(51, 71, 91);
        font-size: 20px;
        font-weight: 600;
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;

    padding: 30px 32px 32px;

    &__button-confirm.vs-button.vs-button-primary {
      background: rgb(51, 71, 91) !important;

      &:hover {
        background: rgba(51, 71, 91, 0.8) !important;
        box-shadow: none !important;
      }
    }
  }

  &__form {
    margin-top: 12px;
    padding: 0 32px;

    &__field {
      margin-top: 24px;

      &:first-child {
        margin-top: 0;
      }

      &_invalid {
        .pa-card-widget__form {
          &__field {
            &__error {
              padding: 4px 0 1px;
              font-size: 12px;
              color: #f2545b !important;
              font-weight: 500;
            }
          }

          &__input {
            border-color: #f2545b !important;
            box-shadow: 0 0 0 1px #f2545b !important;
          }
        }
      }

      &__label {
        padding-bottom: 4px;
        color: #33475b;
        font-size: 14px;
        font-weight: 500;
      }
    }

    &__input {
      padding: 9px 10px;
      width: 100%;
      font-size: 16px;
      color: #33475b;
      background-color: #f5f8fa;
      border: 1px solid #cbd6e2;
      border-radius: 3px;
      transition: all 0.15s ease-out;

      &:focus {
        border-color: rgba(87, 87, 87, 0.5);
        box-shadow: 0 0 4px 1px rgba(87, 87, 87, 0.3), 0 0 0 1px rgb(87, 87, 87);
        outline: 0;
      }
    }

    &__textarea {
      min-width: 100%;
      max-width: 100%;
      min-height: 40px;
    }
  }
}

.pa-card__slots {
  &__title {
    color: rgb(51, 71, 91);
    font-size: 16px;
    font-weight: 600;
  }

  &__timezone-wrapper {
    margin-top: 12px;
  }

  &__list {
    margin-top: 18px;
    overflow-y: auto;
    height: 465px;
    max-height: 465px;

    &__item {
      margin-top: 8px;
      padding: 12px 24px;
      color: rgb(38, 38, 41);
      font-size: 16px;
      text-align: center;
      text-overflow: ellipsis;
      border: 1px solid rgb(203, 214, 226);
      border-radius: 3px;
      transition: all 0.15s ease-out;
      overflow: hidden;
      cursor: pointer;

      &:hover {
        border-color: rgb(239, 241, 245);
        background-color: rgba(244, 250, 255, 0.05);
      }

      &:first-child {
        margin-top: 0;
      }
    }
  }

  &__empty-text {
    margin-top: 30px;
    text-align: center;
    font-size: 18px;
  }
}

.pa-card-iframe {
  margin-left: auto;
  margin-right: auto;
  width: 850px;
  max-width: 100%;
  background-color: rgb(255, 255, 255);
  box-shadow: -4px 4px 12px -2px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  font-family: 'Lato';

  &__inner {
    display: flex;
    width: 100%;
  }
}

.pa-card__slots-wrapper {
  width: 50%;
  padding: 60px 32px;
  overflow: hidden;
}

.pa-card__calendar-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px 0;
  width: 50%;
  background: rgb(239, 241, 245);

  .vc-container {
    max-width: 100%;
    font-family: 'Lato';
    background: rgb(239, 241, 245);
    border: none;
  }

  .vc-header {
    margin-bottom: 24px;
  }

  .vc-title {
    font-size: 22px;
    font-weight: 500;
    line-height: 28px;
    pointer-events: none;
  }

  .vc-arrows-container {
    top: 5px;
    left: 50%;
    transform: translateX(-50%);
  }

  .vc-arrow {
    .vc-svg-icon {
      width: 20px;
      height: 20px;
    }
  }

  .vc-weeks {
    padding: 5px 2px;
  }

  .vc-weekday {
    padding-bottom: 16px;
    text-transform: uppercase;
    color: rgb(51, 71, 91);
    font-size: 14px;
    font-weight: 600;
  }

  .vc-day.is-not-in-month,
  .vc-day.is-not-in-month * {
    opacity: 1 !important;
    // pointer-events: auto !important;
  }

  .vc-day.is-not-in-month *:not(.is-disabled) {
    pointer-events: auto !important;
  }

  .vc-day-content,
  .vc-highlight {
    width: 50px;
    height: 50px;
  }

  .vc-highlight,
  .vc-day-content:focus {
    color: rgb(239, 241, 245) !important;
    background: rgb(51, 71, 91) !important;
  }

  .is-disabled {
    background: none !important;
    pointer-events: none !important;
  }
}

button {
  font-family: inherit;
}

.pathadvice {
  &__contact-form {
    display: flex;
    flex-direction: column;
    font-family: 'Lato', sans-serif;
    height: 100%;
    width: 100%;

    &__privacy-policy {
      display: flex !important;
      border: none;
      padding: 5px 0;

      &__text {
        margin-top: -3px;
        margin-left: 7px;
        color: rgb(87, 87, 87);
        font-weight: 500;
      }

      &__checkbox {
        width: 12px;
        height: 12px;

        input:checked + .vs-checkbox {
          background: rgb(87, 87, 87) !important;
          border-color: rgb(87, 87, 87) !important;
        }

        &.custom-checkbox_active {
          background: rgb(87, 87, 87);
          border-color: rgb(87, 87, 87);

          &::before {
            border-color: rgb(255, 255, 255);
          }
        }

        .vs-checkbox {
          width: 12px;
          min-width: 12px;
          height: 12px;
          min-height: 12px;
          border-color: rgb(87, 87, 87) !important;
        }
      }
    }

    &__main-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      position: relative;
      padding: 0;
      height: calc(100% - 26px);
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      box-shadow: inset 0px 2px 5px #e6ecf1;

      &__actions {
        position: absolute;
        top: 16px;
        right: 10px;
        z-index: 2;
      }

      &__form {
        display: flex;
        flex-direction: column;
        position: relative;
        padding: 30px 28px 10px;
        width: 100%;
        height: calc(100% - 26px);
        max-height: 100%;
        border-radius: 6px;
        overflow-y: auto;
        scrollbar-width: thin;

        &::before,
        &::after {
          content: '';
        }

        &::before {
          margin-top: auto;
        }

        &::after {
          margin-bottom: auto;
        }

        &::-webkit-scrollbar {
          width: 8px;
        }

        &::-webkit-scrollbar-track {
          background: rgba(230, 236, 241, 0.5);
        }

        &::-webkit-scrollbar-thumb:vertical {
          background-color: rgb(87, 87, 87);
          border-radius: 6px;
        }

        &__title {
          font-size: 24px;
          font-weight: 700;
          text-align: center;
          text-transform: uppercase;
        }

        &__loading {
          margin: auto 0;
          padding-bottom: 40px;
        }

        &__checkbox {
          margin: 0 9px;

          &.custom-toggle-switch_active {
            background: #575757;
          }

          &-wrapper {
            margin-bottom: 20px;
          }

          &-label {
            color: inherit;
            font-size: 14px;
            font-weight: 600;
            text-transform: uppercase;

            &.text-opacity {
              color: rgba(#575757, 0.7);
            }
          }
        }

        &__field {
          display: flex;
          flex-direction: column;
          position: relative;
          margin-top: 20px;
          width: 100%;

          &:first-child {
            margin-top: 0;
          }

          &_invalid {
            border-radius: 6px;

            .pathadvice__contact-form__main-container__form {
              &__input {
                border-color: rgb(240, 85, 65);
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
              }
            }
          }

          &__error {
            padding: 0 14px;
            color: rgb(255, 255, 255);
            text-align: left;
            font-size: 13px;
            line-height: 16px;
            background: rgb(240, 85, 65);
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
          }

          &__error-checkbox {
            padding: 0 14px;
            color: rgb(255, 255, 255);
            font-size: 13px;
            line-height: 16px;
            background: rgb(240, 85, 65);
          }

          &__icon {
            position: absolute;
            top: 15px;
            left: 15px;
            width: 16px;
            height: 16px;
            color: rgb(18, 89, 141);

            &-action {
              position: absolute;
              top: 17px;
              right: 16px;
            }
          }
        }

        &__input {
          padding: 11px 11px 11px 43px;
          width: 100%;
          color: #575757;
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          background: rgb(255, 255, 255);
          border: 2px solid rgb(87, 87, 87);
          border-radius: 6px;
          outline: none;
          font-family: 'Lato', sans-serif;

          &::placeholder {
            color: #575757;
            font-family: 'Lato', sans-serif;
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
          }

          &:-webkit-autofill,
          &:-webkit-autofill:hover,
          &:-webkit-autofill:focus,
          &:-webkit-autofill:active {
            transition: background-color 5000s;
            -webkit-text-fill-color: #575757 !important;
          }
        }

        &__textarea {
          height: 100px;
          outline: none;
          resize: none;
          overflow: auto;
          scrollbar-width: thin;

          &::-webkit-scrollbar {
            width: 3px;
          }

          &::-webkit-scrollbar-thumb:vertical {
            background-color: #575757;
            border-radius: 6px;
          }
        }

        &__actions {
          &__btn {
            display: flex;
            justify-content: center;
            width: 100%;
            padding: 10px;
            color: rgb(255, 255, 255);
            font-size: 18px;
            font-weight: 600;
            line-height: 20px;
            text-align: center;
            text-transform: uppercase;
            background: rgb(87, 87, 87);
            border-radius: 6px;
            border: none;
            outline: none;
            cursor: pointer;
            box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);

            span {
              text-transform: uppercase;
            }

            &_transparent {
              background: transparent;
            }
          }
        }

        &-wrapper {
          position: relative;
          height: 100%;
          max-height: 100%;
        }

        &-widget {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }

        &_hidden {
          opacity: 0;
          visibility: hidden;
          overflow: hidden;
        }
      }
    }

    &_desktop {
      .pathadvice {
        &__contact-form {
          &__main-container {
            padding: 0;

            &__form {
              padding: 30px 10px 10px;
            }
          }
        }
      }
    }

    &._without-white-label {
      .pathadvice__contact-form {
        &__main-container {
          height: 100%;
        }
      }
    }
  }
}
</style>
