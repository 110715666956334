var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "20",
        height: "20",
        viewBox: "0 0 20 20",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M14.4001 10.0823C15.9903 10.6947 17.7759 9.90195 18.3882 8.31173C19.0006 6.7215 18.2079 4.93596 16.6176 4.32361C15.0274 3.71125 13.2419 4.50398 12.6295 6.0942C12.0172 7.68443 12.8099 9.46997 14.4001 10.0823Z",
          stroke: "#575757",
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M3.59901 14.766C3.20677 14.8991 2.99671 15.325 3.12982 15.7172C3.26293 16.1095 3.68881 16.3195 4.08106 16.1864L3.59901 14.766ZM8.91235 5.95699C8.59077 7.253 7.92058 9.27019 6.96817 11.0758C6.4924 11.9777 5.9572 12.8057 5.37557 13.4606C4.79044 14.1195 4.19261 14.5645 3.59901 14.766L4.08106 16.1864C5.00879 15.8716 5.81346 15.2265 6.49711 14.4567C7.18427 13.683 7.78416 12.7439 8.29491 11.7756C9.31555 9.84067 10.0241 7.70486 10.3682 6.31823L8.91235 5.95699Z",
          fill: "#575757",
        },
      }),
      _c("path", {
        attrs: {
          d: "M12.349 16.9437C11.9568 17.0768 11.7467 17.5027 11.8798 17.895C12.0129 18.2872 12.4388 18.4973 12.8311 18.3641L12.349 16.9437ZM17.6624 8.13473C17.3408 9.43073 16.6706 11.4479 15.7182 13.2535C15.2424 14.1555 14.7072 14.9835 14.1256 15.6384C13.5404 16.2972 12.9426 16.7423 12.349 16.9437L12.8311 18.3641C13.7588 18.0493 14.5635 17.4042 15.2471 16.6344C15.9343 15.8607 16.5342 14.9216 17.0449 13.9533C18.0655 12.0184 18.7741 9.88259 19.1182 8.49597L17.6624 8.13473Z",
          fill: "#575757",
        },
      }),
      _c("path", {
        attrs: {
          d: "M5.65207 7.91045C7.2423 8.5228 9.02784 7.73008 9.64019 6.13985C10.2525 4.54963 9.45982 2.76408 7.86959 2.15173C6.27937 1.53938 4.49382 2.3321 3.88147 3.92233C3.26912 5.51255 4.06184 7.2981 5.65207 7.91045Z",
          stroke: "#575757",
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }