var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pa-card-wrapper m-0 w-full",
      class: {
        "pa-card-wrapper_mobile": _vm.isMobile,
        pa_iframe: _vm.isLoadedInIframe,
      },
    },
    [
      _c(
        "div",
        { staticClass: "pa-card-widget" },
        [
          !_vm.isLoading && _vm.company && _vm.appointment
            ? [
                !_vm.hasWhiteLabel && !_vm.isLoadedInIframe
                  ? _c("div", { staticClass: "pa-card__main-heading" }, [
                      _vm._m(0),
                    ])
                  : _vm._e(),
                _vm.isLoadedInIframe
                  ? _c(
                      "button",
                      {
                        staticClass: "pathadvice__button-back",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.sendBackEventToParent.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      },
                      [
                        _c("ArrowIcon", {
                          staticClass: "mr-2",
                          attrs: { width: 9, height: 9, color: "#575757" },
                        }),
                        _vm._v(
                          "\n\n        " +
                            _vm._s(_vm.$tp("appointmentBooking.back")) +
                            "\n      "
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.isSent
                  ? _c("div", { staticClass: "pa-card__main-title" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$tp("appointmentBooking.chooseTheRightTimeForYou")
                        )
                      ),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "pa-card__widget" }, [
                  !_vm.isFormStep
                    ? _c("div", { staticClass: "pa-card-iframe" }, [
                        _c(
                          "div",
                          {
                            staticClass: "pa-card-iframe__inner",
                            class: {
                              "pa-card-iframe__inner-slots-step":
                                _vm.isSlotsStep,
                            },
                          },
                          [
                            (_vm.isMobile ? _vm.isDatePickerStep : true)
                              ? _c(
                                  "div",
                                  { staticClass: "pa-card__calendar-wrapper" },
                                  [
                                    _vm.uploadedPictureURL
                                      ? _c("Avatar", {
                                          staticClass: "pa-card__widget-avatar",
                                          attrs: {
                                            size: "72px",
                                            photoURL: _vm.uploadedPictureURL,
                                          },
                                        })
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      { staticClass: "pa-card__widget-title" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.appointment.name ||
                                              _vm.appointment.companyDisplayName
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("DatePicker", {
                                      ref: "calendar",
                                      attrs: {
                                        locale: _vm.$i18n.locale,
                                        "is-expanded": true,
                                        "is-required": true,
                                        "min-page": _vm.minPage,
                                        "max-page": _vm.maxPage,
                                        "disabled-dates": _vm.disabledDates,
                                        "available-dates": _vm.availableDates,
                                        value: _vm.selectedDate,
                                        masks: {
                                          title: "MMMM",
                                          weekdays: "WWW",
                                        },
                                        attributes: _vm.attributes,
                                      },
                                      on: {
                                        dayclick: _vm.onDayClick,
                                        "update:page": _vm.pageChange,
                                        "update:from-page": _vm.pageChange,
                                      },
                                      model: {
                                        value: _vm.selectedDate,
                                        callback: function ($$v) {
                                          _vm.selectedDate = $$v
                                        },
                                        expression: "selectedDate",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            (_vm.isMobile ? _vm.isSlotsStep : true)
                              ? _c(
                                  "div",
                                  { staticClass: "pa-card__slots-wrapper" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "pa-card__slots__title" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$tp(
                                              "appointmentBooking.whatTimeWorksBest"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "pa-card__slots__timezone-wrapper",
                                      },
                                      [
                                        _c("select-timezone", {
                                          attrs: {
                                            isDisabled:
                                              !_vm.availableDates ||
                                              _vm.availableDates.length === 0,
                                          },
                                          on: {
                                            "timezone-selected":
                                              _vm.onTimezoneSelected,
                                            "selected-hour-format":
                                              _vm.onSelectedHourFormat,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm.filteredSelectedWeekDays &&
                                    _vm.filteredSelectedWeekDays.times &&
                                    _vm.filteredSelectedWeekDays.times.length
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "pa-card__slots__list",
                                            style:
                                              "" +
                                              (_vm.isLoadedInIframe
                                                ? "max-height: " +
                                                  _vm.maxHeightSlotsList +
                                                  "px"
                                                : ""),
                                          },
                                          _vm._l(
                                            _vm.filteredSelectedWeekDays.times,
                                            function (slot) {
                                              return _c(
                                                "div",
                                                {
                                                  key: slot.dateFormated,
                                                  staticClass:
                                                    "pa-card__slots__list__item",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.selectSlot(
                                                        slot
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        _vm.displaySlotTime(
                                                          slot
                                                        )
                                                      ) +
                                                      "\n                "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      : _c(
                                          "div",
                                          {
                                            staticClass:
                                              "pa-card__slots__empty-text",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$tp(
                                                  "appointmentBooking.noFreeSlotAvailable"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                        _vm.isSlotsStep && _vm.isMobile
                          ? _c(
                              "div",
                              { staticClass: "pa-card-widget__footer" },
                              [
                                _c(
                                  "vs-button",
                                  {
                                    attrs: { type: "border", color: "#275D73" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        _vm.isDatePickerStep = true
                                        _vm.isSlotsStep = false
                                      },
                                    },
                                  },
                                  [
                                    _c("ArrowIcon", {
                                      staticClass: "mr-2",
                                      attrs: {
                                        width: 9,
                                        height: 9,
                                        color: "#275D73",
                                      },
                                    }),
                                    _vm._v(
                                      "\n\n              " +
                                        _vm._s(
                                          _vm.$tp("appointmentBooking.back")
                                        ) +
                                        "\n            "
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ]),
                _vm.isFormStep && !_vm.isSent
                  ? _c("div", { staticClass: "pa-card-widget-data-wrapper" }, [
                      _c("div", { staticClass: "pa-card-widget-data" }, [
                        _c(
                          "div",
                          { staticClass: "pa-card-widget-data__heading" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "pa-card-widget-data__heading__title",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$tp(
                                        "appointmentBooking.yourInformation"
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "pa-card-widget-data__heading__date-wrapper",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "pa-card-widget-data__heading__date",
                                  },
                                  [_vm._v(_vm._s(_vm.formattedDate))]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "pa-card-widget-data__heading__date-button",
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        _vm.isFormStep = false
                                        _vm.isSlotsStep = true
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.$tp("appointmentBooking.edit")
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c("div", { staticClass: "mt-1" }, [
                              _vm._v(_vm._s(_vm.appointment.duration) + " min"),
                            ]),
                            _c("div", { staticClass: "mt-1" }, [
                              _vm._v(
                                _vm._s(_vm.onlineMeetingToolOfSelectedAgent)
                              ),
                            ]),
                          ]
                        ),
                        _c("div", { staticClass: "pa-card-widget__form" }, [
                          _c(
                            "div",
                            {
                              staticClass: "pa-card-widget__form__field",
                              class: {
                                "pa-card-widget__form__field_invalid":
                                  _vm.errors.first("name"),
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "pa-card-widget__form__field__label",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("modifyInputPlaceholder")(
                                        _vm.$tp("appointmentBooking.name"),
                                        true
                                      )
                                    )
                                  ),
                                ]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.name,
                                    expression: "name",
                                  },
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: {
                                      required: true,
                                      max: 80,
                                    },
                                    expression:
                                      "{\n                  required: true,\n                  max: 80\n                }",
                                  },
                                ],
                                key: "name",
                                staticClass: "pa-card-widget__form__input",
                                attrs: { name: "name" },
                                domProps: { value: _vm.name },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.name = $event.target.value
                                  },
                                },
                              }),
                              _vm.errors.first("name")
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "pa-card-widget__form__field__error",
                                    },
                                    [
                                      _vm._v(
                                        "* " + _vm._s(_vm.errors.first("name"))
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "pa-card-widget__form__field",
                              class: {
                                "pa-card-widget__form__field_invalid":
                                  _vm.errors.first("email"),
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "pa-card-widget__form__field__label",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("modifyInputPlaceholder")(
                                        _vm.$tp(
                                          "appointmentBooking.emailAddress"
                                        ),
                                        true
                                      )
                                    )
                                  ),
                                ]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.email,
                                    expression: "email",
                                  },
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: {
                                      required: true,
                                      email: true,
                                    },
                                    expression:
                                      "{\n                  required: true,\n                  email: true\n                }",
                                  },
                                ],
                                key: "email",
                                staticClass: "pa-card-widget__form__input",
                                attrs: { name: "email" },
                                domProps: { value: _vm.email },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.email = $event.target.value
                                  },
                                },
                              }),
                              _vm.errors.first("email")
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "pa-card-widget__form__field__error",
                                    },
                                    [
                                      _vm._v(
                                        "* " + _vm._s(_vm.errors.first("email"))
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "pa-card-widget__form__field",
                              class: {
                                "pa-card-widget__form__field_invalid":
                                  _vm.errors.first("message"),
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "pa-card-widget__form__field__label",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("modifyInputPlaceholder")(
                                        _vm.$tp("appointmentBooking.message"),
                                        true
                                      )
                                    )
                                  ),
                                ]
                              ),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.message,
                                    expression: "message",
                                  },
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: {
                                      required: true,
                                      max: 500,
                                    },
                                    expression:
                                      "{\n                  required: true,\n                  max: 500\n                }",
                                  },
                                ],
                                key: "message",
                                staticClass:
                                  "pa-card-widget__form__input pa-card-widget__form__textarea",
                                attrs: { name: "message" },
                                domProps: { value: _vm.message },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.message = $event.target.value
                                  },
                                },
                              }),
                              _vm.errors.first("message")
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "pa-card-widget__form__field__error",
                                    },
                                    [
                                      _vm._v(
                                        "* " +
                                          _vm._s(_vm.errors.first("message"))
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm.appointment &&
                          _vm.appointment.isPrivacyInformationEnabled
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "pa-card-widget__form__field _privacy",
                                  class: {
                                    "pa-card-widget__form__field_invalid":
                                      _vm.errors.first("privacy-agreement"),
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex flex-row pathadvice__contact-form__privacy-policy-wrapper",
                                      class: {
                                        "pathadvice__contact-form__privacy-policy":
                                          _vm.errors.first("privacy-agreement"),
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _c("vs-checkbox", {
                                            directives: [
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: "required:true",
                                                expression: "'required:true'",
                                              },
                                            ],
                                            key: "privacy-agreement",
                                            staticClass:
                                              "pathadvice__contact-form__privacy-policy__checkbox",
                                            attrs: {
                                              name: "privacy-agreement",
                                            },
                                            model: {
                                              value: _vm.hasAgreedToPrivacy,
                                              callback: function ($$v) {
                                                _vm.hasAgreedToPrivacy = $$v
                                              },
                                              expression: "hasAgreedToPrivacy",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex flex-row pathadvice__contact-form__privacy-policy__text",
                                        },
                                        [
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.appointment
                                                  .privacyInformationHtml
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm.errors.first("privacy-agreement")
                                    ? _c(
                                        "p",
                                        {
                                          staticClass:
                                            "pathadvice__contact-form__main-container__form__field__error-checkbox",
                                        },
                                        [
                                          _vm._v(
                                            "\n                * " +
                                              _vm._s(
                                                _vm.errors.first(
                                                  "privacy-agreement"
                                                )
                                              ) +
                                              "\n              "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "pa-card-widget__footer pa-card-widget__footer-form",
                          },
                          [
                            _c(
                              "vs-button",
                              {
                                attrs: { type: "border", color: "#275D73" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    _vm.isFormStep = false
                                    _vm.isSlotsStep = true
                                  },
                                },
                              },
                              [
                                _c("ArrowIcon", {
                                  staticClass: "mr-2",
                                  attrs: {
                                    width: 9,
                                    height: 9,
                                    color: "#275D73",
                                  },
                                }),
                                _vm._v(
                                  "\n\n              " +
                                    _vm._s(_vm.$tp("appointmentBooking.back")) +
                                    "\n            "
                                ),
                              ],
                              1
                            ),
                            _vm.isFormStep
                              ? _c(
                                  "vs-button",
                                  {
                                    staticClass:
                                      "pa-card-widget__footer__button-confirm",
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.submit.apply(null, arguments)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$tp("appointmentBooking.confirm")
                                      )
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.isSent
                  ? _c("div", { staticClass: "pa-card-widget-data-wrapper" }, [
                      _c("div", { staticClass: "pa-card__end-screen" }, [
                        _c(
                          "div",
                          { staticClass: "pa-card__end-screen__heading" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "mb-0 pa-card__main-title pa-card__end-screen__title",
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$tp(
                                      "appointmentBooking.bookingSlotDefaultTitle"
                                    )
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "pa-card__end-screen__description",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$tp(
                                        "appointmentBooking.bookingSlotDefaultCopy"
                                      )
                                    ) +
                                    " "
                                ),
                                _c("br"),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$tp(
                                        "appointmentBooking.bookingSlotInvitationSent"
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "pa-card__end-screen__info" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "pa-card__end-screen__info__company",
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.appointment.companyDisplayName ||
                                      _vm.company.companyDisplayName ||
                                      _vm.company.name
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "pa-card__end-screen__info__name",
                              },
                              [
                                _c("QuoteIcon", {
                                  attrs: { width: 16, height: 16 },
                                }),
                                _c("div", { staticClass: "ml-2" }, [
                                  _vm._v(_vm._s(_vm.appointment.name)),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "pa-card__end-screen__info__date-wrapper",
                              },
                              [
                                _c("CalendarIcon", {
                                  attrs: {
                                    width: 16,
                                    height: 16,
                                    color: "#12598D",
                                  },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "ml-3 pa-card__end-screen__info__date",
                                  },
                                  [_vm._v(_vm._s(_vm.formattedDate))]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      ),
      !_vm.isLoading && !(_vm.company || _vm.appointment)
        ? _c("div", { staticClass: "pa-card__title" }, [
            _vm._v(
              "\n    " +
                _vm._s(_vm.$tp("appointmentBooking.companyNotFound")) +
                "\n  "
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "pa-card__main-heading__logo-wrapper",
        attrs: { href: "https://www.letsconnect.at/" },
      },
      [
        _c("img", {
          staticClass: "pa-card__main-heading__logo-img",
          attrs: {
            src: require("@/assets/images/register-funnel/vega_logo.svg"),
            alt: "Logo",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }